import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    reportid: Number,
    evidencetype: String,
    newMessage: String,
    updatestatusurl: String,
  };

  static targets = [
    "evidenceMessages",
    "newMessage",
    "newAttachment",
    "feedbackProvidedInput",
    "readyForReviewInput",
    "filePreview",
    "filePreviewName",
    "filePreviewSize",
  ];

  connect() {
    this.attachmentPreviewListener();
    this.textareaSizeListener();

    fetch(`/message/${this.reportidValue}/${this.evidencetypeValue}`)
      .then((response) => response.text())
      .then((html) => { this.evidenceMessagesTarget.innerHTML = html; this.scrollToBottom() });

  }

  attachmentPreviewListener() {
    var self = this;
    this.newAttachmentTarget.addEventListener("change", function (e) {
      if (this.files) {
        self.filePreviewNameTarget.innerHTML = this.files[0].name;
        let fileSize = self.formatBytes(this.files[0].size);
        self.filePreviewSizeTarget.innerHTML = fileSize;
        self.filePreviewTarget.classList.remove("hidden");
      }
    });
  }

  textareaSizeListener() {
    var self = this;
    var growers = document.querySelectorAll(".grow-wrap");
    growers.forEach((grower) => {
      const textarea = grower.querySelector("textarea");
      textarea.addEventListener("input", () => {
        grower.dataset.replicatedValue = textarea.value;
      });
    });
  }

  resetGrowers() {
    var growers = document.querySelectorAll(".grow-wrap");
    growers.forEach((grower) => {
      grower.dataset.replicatedValue = "";
    });
  }

  clearAttachment() {
    this.newAttachmentTarget.value = null;
    this.filePreviewTarget.classList.add("hidden");
  }

  scrollToBottom() {
    let innerContainer = document.querySelector("#inner-messages-container");
    this.evidenceMessagesTarget.scrollTop = innerContainer.offsetHeight;
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  async sendMessage() {
    const csrfToken = this.getCookie("csrftoken");
    const formData = new FormData();
    formData.append("message", this.newMessageTarget.value);
    formData.append("attachment", this.newAttachmentTarget.files[0]);
    this.newMessageTarget.value = "";
    this.filePreviewTarget.classList.add("hidden");
    this.newAttachmentTarget.value = null;
    this.resetGrowers();
    const r = await fetch(
      `/message/${this.reportidValue}/${this.evidencetypeValue}`,
      {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
        },
        body: formData,
      }
    );
    const text = await r.text();
    this.evidenceMessagesTarget.innerHTML = text;

    if (text) {
      this.scrollToBottom();
    }
  }

  async feedbackProvided() {
    let evidence_status = "in_progress";
    if (this.feedbackProvidedInputTarget.checked) {
      evidence_status = "feedback_provided";
    }
    const csrfToken = this.getCookie("csrftoken");
    const formData = new FormData();
    formData.append("evidence_type", this.evidencetypeValue);
    formData.append("evidence_status", evidence_status);
    const r = await fetch(this.updatestatusurlValue, {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
      },
      body: formData,
    });
    const text = await r.text();
    if (r.status == 200) {
      location.reload();
    }
  }

  async readyForReview() {
    let evidence_status = "in_progress";
    if (this.readyForReviewInputTarget.checked) {
      evidence_status = "ready_for_review";
    }
    const csrfToken = this.getCookie("csrftoken");
    const formData = new FormData();
    formData.append("evidence_type", this.evidencetypeValue);
    formData.append("evidence_status", evidence_status);
    const r = await fetch(this.updatestatusurlValue, {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
      },
      body: formData,
    });
    const text = await r.text();
    if (r.status == 200) {
      location.reload();
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      "Bytes",
      "KiB",
      "MiB",
      "GiB",
      "TiB",
      "PiB",
      "EiB",
      "ZiB",
      "YiB",
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "item"];
  static visibleRequired = [];

  connect() {

    this.connected = true
    this.hide_show('id_has_subsidiaries','id_subsidiaries_description'); // Onboarding
    this.hide_show('id_return_flight','id_return_flight_departure_date_month'); // Air Transport Report

    this.hide_show('id_mode_of_transport','id_car_make_and_model,id_fuel_efficiency,id_fuel_type','car,motorcycle','.mb-8');
    this.hide_show('id_mode_of_transport','id_distance_travelled_per_shift','car,motorcycle','.mb-8',false);
    this.visibleRequired = ['id_car_make_and_model','id_fuel_type'];
    this.requiredonShow();
  }

  reveal() {
    this.itemTarget.classList.remove("opacity-0", "invisible");
  }

  hide(event) {
    if (
      this.itemTarget.contains(event.target) ||
      this.buttonTarget.contains(event.target)
    ) {
      return;
    }
    this.itemTarget.classList.add("opacity-0", "invisible");
  }

  hide_show(id,targetIds,equalValues = '',parentClass = '',operation = true){

    const checkbox = document.getElementById(id);
    if (checkbox && !checkbox.hasBeenProcessed) {
      checkbox.hasBeenProcessed = true;
      const targetIdsArray = targetIds.split(',').map(targetId => targetId.trim());
      const targets = targetIdsArray.map(targetId => document.getElementById(targetId));
      // const targetParents = targets.map(target => target.parentNode);
      const targetParents = targets.map(target => {
        if (!parentClass) {
          return target.parentNode;
        } else {
          return target.closest(parentClass);
        }
      });
      const currentDisplays = targetParents.map(targetParent => window.getComputedStyle(targetParent).display);
  
      const updateDisplay = () => {
        if (equalValues) {
            const valuesArray = equalValues.split(',').map(value => value.trim());
            // const selectedExist = this.checkSelectedExistence(checkbox.value, equalValues);
            for (let i = 0; i < targets.length; i++) {
              const selectedExist = valuesArray.some(value => this.checkSelectedExistence(checkbox.value, value));
              if(operation){
                targets[i].style.display = selectedExist ? currentDisplays[i] : 'none';
                targetParents[i].style.display = selectedExist ? currentDisplays[i] : 'none';
                this.requiredonShow();
                this.updateLabels(targetParents[i],checkbox.value)
              }else{
                targets[i].style.display = selectedExist ? 'none':currentDisplays[i];
                targetParents[i].style.display = selectedExist ? 'none':currentDisplays[i];
                this.requiredonShow();
                this.updateLabels(targetParents[i],checkbox.value)
              }
            }
        } else {
          const isChecked = checkbox.checked;
          for (let i = 0; i < targets.length; i++) {
            targets[i].style.display = isChecked ? currentDisplays[i] : 'none';
            targetParents[i].style.display = isChecked ? currentDisplays[i] : 'none';
            this.requiredonShow();
            this.updateLabels(targetParents[i],isChecked)
          }
        }
      };
  
      checkbox.addEventListener('change', updateDisplay);
  
      // Set initial state based on checkbox state
      updateDisplay();
    }

  }

  requiredonShow(){
    let fields = this.visibleRequired;
    if (fields && Array.isArray(fields) && fields.length > 0) {
      fields.forEach(fieldId => {
        const field = document.getElementById(fieldId);
        if (field) {
          const computedStyle = window.getComputedStyle(field);
          const isVisible = computedStyle.display !== 'none' && computedStyle.visibility !== 'hidden';
  
          if (isVisible) {
            field.required = true;
          } else {
            field.required = false;
          }
        }
      });
    }
    this.updateLabels()
  }

  updateLabels(target,value){
    const labels = [
      {
        equalValue:'car',
        from:'Motorcycle',
        to:'Car',
      },
      {
        equalValue:'motorcycle',
        from:'Car',
        to:'Motorcycle',
      }
    ]
    if(value){
      labels.forEach(label => {
        if(label.equalValue == value){
          const currentLabel = target.getElementsByTagName("label")[0].innerHTML;
          target.getElementsByTagName("label")[0].innerHTML = currentLabel.replace(label.from, label.to);
        }
      });
    }
  }

  checkSelectedExistence(wordToCheck, wordsString) {
    const wordsArray = wordsString.split(',');
    const trimmedWords = wordsArray.map(word => word.trim());
    return trimmedWords.includes(wordToCheck);
  }

  forceHide(event) {
    if (event.target !== event.currentTarget) return;
    this.itemTarget.classList.add("opacity-0", "invisible");
  }

}

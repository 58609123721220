import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {}

  reveal() {
    this.itemTarget.classList.remove("opacity-0", "invisible");
  }

  hide() {
    this.itemTarget.classList.add("opacity-0", "invisible");
  }
}

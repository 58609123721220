import { Application } from "@hotwired/stimulus";
import HoverRevealController from "./controllers/hover_reveal_controller";
import ClickRevealController from "./controllers/click_reveal_controller";
import ModalController from "./controllers/modal_controller";
import NavFilterController from "./controllers/filter_nav_controller";
import RemovalsController from "./controllers/removals_controller";
import EvidenceMessageController from "./controllers/evidence_message_controller";
import ClickCopyController from "./controllers/click_copy_controller";
import FileInputController from "./controllers/file_input_controller";

window.Stimulus = Application.start();

Stimulus.register("hover-reveal", HoverRevealController);
Stimulus.register("click-reveal", ClickRevealController);
Stimulus.register("modal-control", ModalController);
Stimulus.register("removals", RemovalsController);
Stimulus.register("filter-nav", NavFilterController);
Stimulus.register("evidence-message", EvidenceMessageController);
Stimulus.register("click-copy", ClickCopyController);
Stimulus.register("file-input", FileInputController);

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  targetWrap = ".report-nav";
  emptyElement = ".no-item";

  connect() {}

  filterItem(event) {
    //console.log('Q1--',this.buttonTarget.getAttribute("data-filter"));
    const filter = this.buttonTarget.getAttribute("data-filter");

    if (this.targetWrap) {
      const targetElement = document.querySelector(this.targetWrap);
      const allItems = targetElement.querySelectorAll(".nav-item");
      allItems.forEach((element) => {
        if (filter) {
          const classes = Array.from(element.classList);
          if (classes.includes("status-" + filter)) {
            element.classList.remove("hidden");
          } else {
            element.classList.add("hidden");
          }
        } else {
          element.classList.remove("hidden");
        }
      });

      // Empty State
      const emptyElement = document.querySelector(this.emptyElement);
      if (filter) {
        const getElemStatus = targetElement.querySelectorAll(
          ".status-" + filter
        );
        if (getElemStatus.length) {
          emptyElement.classList.add("hidden");
        } else {
          emptyElement.classList.remove("hidden");
        }
      } else {
        emptyElement.classList.add("hidden");
      }
    }

    const elementsWithNavFilterActive =
      document.querySelectorAll(".nav-filter-active");
    // Loop through the selected elements and remove the class
    elementsWithNavFilterActive.forEach(function (element) {
      element.classList.remove("nav-filter-active");
    });
    this.buttonTarget.classList.add("nav-filter-active");
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {}

  showPreview(event) {
    const button = event.target.closest("[data-button]");
    if (button) {
      const dataSource = button.getAttribute("data-source");
      const dataTitle = button.getAttribute("data-title");

      const previewModal = document.querySelector(".preview-modal");
      if(previewModal){
          previewModal.querySelector("#downloadPreview").href = dataSource;
          previewModal.querySelector("#modal-title").textContent = dataTitle
          previewModal.querySelector("iframe").src = dataSource
          previewModal.classList.remove("hidden");
      }
    }
    event.stopPropagation();
  }

  closePreview(event){
    const modal = document.querySelector(".preview-modal");
    modal.classList.add("hidden");
    event.stopPropagation();
  }

}

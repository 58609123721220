import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "buttonText"];
  connect() {}

  copyText() {
    if (this.hasTextTarget) {
      let base_url = window.location.origin;
      navigator.clipboard.writeText(base_url + this.textTarget.innerHTML);
      this.buttonTextTarget.innerHTML = "Copied";
      setTimeout(() => {
        this.buttonTextTarget.innerHTML = "Copy Link";
      }, 5000);
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inner"];
  connect() {
    let checkbox = this.innerTarget.querySelector("input[type='checkbox']");
    let label = this.innerTarget.querySelector("label");
    if (checkbox && label) {
      let wrapper = document.createElement("div");
      wrapper.classList.add("form-group", "checkbox", "text-base");
      checkbox.parentNode.insertBefore(wrapper, checkbox);
      wrapper.appendChild(checkbox);
      wrapper.appendChild(label);
    }
  }
}
